import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Md5 } from "ts-md5/dist/md5";

import { IUser } from "../models/user";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";

/**
 * Todo add Hook to LoadingService
 */

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<IUser>;
  public currentUser: Observable<IUser>;

  constructor(private http: HttpClient, private router: Router, private keycloak: KeycloakService) {
    this.currentUserSubject = new BehaviorSubject<IUser>(
      JSON.parse(localStorage.getItem("token"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): IUser {
    return this.currentUserSubject.value;
  }

  apiUrl = environment.apiUrl;

  // Login
  login(username: string, password: string) {
    const baseUsername = username;
    const basePassword = Md5.hashStr(password); // TODO durch sha256 ersetzen

    return this.http
      .post<any>(
        this.apiUrl + "/user/login",
        { username: baseUsername, password: basePassword },
        {}
      )
      .pipe(
        map((user) => {
          if (user && user.token) {
            const extra = {
              prename: user.firstName,
              lastname: user.lastName,
              company: user.company,
              admin: user.admin,
              phone: user.phone,
              mail: user.mail
            };

            localStorage.setItem("token", JSON.stringify(user.token));
            localStorage.setItem("extra", JSON.stringify(extra));

            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("extra");
    this.currentUserSubject.next(null);
    this.router.navigate(["auth"]);
  }

  createAuthHeaderValue(): string {
    const token = localStorage.getItem("token");
    if(this.keycloak.isLoggedIn){
      this.keycloak.getToken().then((toki) => {
        return "Bearer "+toki;
      })
    }else{
      if (token) {
        return JSON.parse(token);
      }
      return "";
    }
  }
}