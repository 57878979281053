import { Component, OnInit } from "@angular/core";
import { NbDialogRef, NbToastrService, NbDialogService } from "@nebular/theme";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmWithPasswordDialogComponent } from "../confirm-with-password-dialog/confirm-with-password-dialog.component";
import { CustomerDocumentationService } from "../../core/services/customer-documentation.service";
import { environment } from "src/environments/environment";
import { EvaluationService } from "../../core/services/evaluation.service";
@Component({
  selector: 'app-evaluation-dialog',
  templateUrl: './evaluation-dialog.component.html',
  styleUrls: ['./evaluation-dialog.component.scss']
})
export class EvaluationDialogComponent implements OnInit {
  public isAdminView: boolean;

  public eval: any;
  public branchID: string;
  public pdfURL: string;
  public pdf: any;
  public permissionWrite: boolean = false;

  private apiUrl: string = environment.apiUrl;
  constructor(    public translate: TranslateService,
    protected ref: NbDialogRef<EvaluationDialogComponent>,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private evaluationService: EvaluationService,
    private documentationService: CustomerDocumentationService) { }
  ngOnInit(): void {
    if(this.eval.customerSignature === undefined){
      this.eval.customerSignature = []
      this.eval.customerSignature.name = "";
      this.eval.customerSignature.userID = "";
      this.eval.customerSignature.signedAt = "";
    }
    if(this.eval.matuszakSignature === undefined){
      this.eval.matuszakSignature = []
      this.eval.matuszakSignature.name = "";
      this.eval.matuszakSignature.userID = "";
      this.eval.matuszakSignature.signedAt = "";

    }
  }

  close(): void {
    this.ref.close();
  }

  toggleMatuszakSignature(event): void {
    event.preventDefault();
    
    this.dialogService
      .open(ConfirmWithPasswordDialogComponent, {
        context: {},
      })
      .onClose.subscribe((hashedUserPW) => {
        if (hashedUserPW) {
          
          this.evaluationService
            .signEvaluation({
              hashedUserPW,
              branchID: this.branchID,
            }, this.eval._id)
            .then(
              () => {
                this.ref.close(true);

                this.toastrService.show(
                  "",
                  this.translate.instant(
                    "customerViewer.dashboard.maßnahmenSuccessfullyModified"
                  ),
                  {
                    status: "success",
                  }
                );
              },
              (error) => {
                this.toastrService.show(
                  "",
                  error.includes("409")
                    ? this.translate.instant("common.login.wrongCredentials")
                    : this.translate.instant(
                        "common.error.anUnknownErrorOccured"
                      ),
                  {
                    status: "danger",
                  }
                );
              }
            );
        }
      });
  }

  toggleCustomerSignature(event): void {
    event.preventDefault();

    this.dialogService
      .open(ConfirmWithPasswordDialogComponent, {
        context: {},
      })
      .onClose.subscribe((hashedUserPW) => {
        if (hashedUserPW) {
          this.documentationService
            .signEvaluations({
              hashedUserPW,
              branchID: this.branchID,
            }, this.eval._id)
            .then(
              () => {
                this.ref.close(true);

                this.toastrService.show(
                  "",
                  this.translate.instant(
                    "customerViewer.dashboard.maßnahmenSuccessfullyModified"
                  ),
                  {
                    status: "success",
                  }
                );
              },
              (error) => {
                this.toastrService.show(
                  "",
                  error.includes("409")
                    ? this.translate.instant("common.login.wrongCredentials")
                    : this.translate.instant(
                        "common.error.anUnknownErrorOccured"
                      ),
                  {
                    status: "danger",
                  }
                );
              }
            );
        }
      });
  }

}
