<nb-menu [items]="items" autoCollapse="true"></nb-menu>
<div class="legal">
    <div class="version">
        <p>v{{ currentAppVersion }}</p>
    </div>
    <div class="impressum">
        <a class="menu-title" href="https://matuszak-hygiene.de/impressum/" target="_blank"> {{ "customerViewer.imprint" | translate }} </a>
    </div>
    <div class="datenschutz">
        <a class="menu-title" href="https://matuszak-hygiene.de/datenschutzerklaerung/" target="_blank"> {{ "customerViewer.privacypolicy" | translate }} </a>
    </div>
</div>
