import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Observable } from "rxjs";
/**
 * Todo add Hook to LoadingService
 */

@Injectable({
  providedIn: "root"
})
export class NavigationService {

  constructor() {
    this._selectedObject.next(JSON.parse(localStorage.getItem("selectedObject")));
  }
  private _selectedObject = new Subject<string>();
  selectedObject$ = this._selectedObject.asObservable();

  updateSelectedObject() {
    this._selectedObject.next(JSON.parse(localStorage.getItem("selectedObject")))
  }

}
