import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";

import { LoaderService } from "../services/loader.service";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    constructor(public loaderService: LoaderService) { }
    public serviceCount = 0;
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //console.log("INTERCEPTING")
        this.serviceCount++;
        this.loaderService.show();
        return next.handle(req).pipe(
            finalize(() => {
                //console.log("Interception Finished")
                this.serviceCount--;
                if (this.serviceCount < 1) {
                    this.loaderService.hide()
                }
            })
        );
    }
}