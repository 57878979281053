import { Component, OnInit} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NbToastrService } from "@nebular/theme";
import { UserManagementService } from "../../core/services/user-management.service";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit{
  public currentPassword: string = "";
  public newPassword: string;
  public repeatPassword: string;
  public PWC: boolean = false;

  public msg: string = this.translate.instant("changepassword.eightchars");
  userInfo;

  constructor(
    private userService: UserManagementService,
    private translate: TranslateService,
    private toastrService: NbToastrService
  ) {}

  ngOnInit() {
    this.userInfo = JSON.parse(localStorage.getItem("extra"));
  }

  change(): void {
    if(this.newPassword.length < 8){
      this.PWC = false
      this.msg = this.translate.instant("changepassword.eightchars"); //"Das neue Passwort muss mindestens 8 Zeichen enthalten"
    }else{
      var re = /^(?=.*\d)(?=.*[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      if(re.test(this.newPassword)){
        if(this.newPassword !== this.repeatPassword){
          this.PWC = false
          this.msg = this.translate.instant("changepassword.repeat") //"Die wiederholung des neuen Passwortes stimmt nicht überein."
        }else{
          if(this.currentPassword.length > 0){
            this.PWC = true
            this.msg = ""
          }else{
            this.PWC = false
            this.msg = this.translate.instant("changepassword.currentPassword") //"Das Feld mit dem aktuellen Passwort muss ausgefüllt sein."
          }
        }
      }else{
        this.PWC = false
        var t2 = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        var bmsg = ""
        this.msg = this.translate.instant("changepassword.request") //"Das Passwort muss mindestens 1 Sonderzeichen, 1 Großbuchstaben & 1 Kleinbuchstaben enthalten."
        if(!t2.test(this.newPassword)){
          bmsg += this.translate.instant("changepassword.regRem1") //1 Sonderzeichen " 
        }
        var t3 = /^(?=.*[A-Z])/
        if(!t3.test(this.newPassword)){
          bmsg += this.translate.instant("changepassword.regRem2")// "1 Großbuchstaben "
        }
        var t4 = /^(?=.*[a-z])/
        if(!t4.test(this.newPassword)){
          bmsg += this.translate.instant("changepassword.regRem3") //"1 Kleinbuchstaben"
        }

        var t5 = /^(?=.*[1-9])/
        if(!t5.test(this.newPassword)){
          bmsg += this.translate.instant("changepassword.regRem4") //"1 Zahl "
        }

        this.msg = this.translate.instant("changepassword.preS") + bmsg + " "+this.translate.instant("changepassword.afterS") //"Das Passwort muss mindestens "+bmsg

      }
      
    }
  }

  changeUserPassword() {
    if (
      this.currentPassword.length > 0 &&
      this.newPassword.length > 7 &&
      this.repeatPassword.length > 7 &&
      this.PWC && 
      this.newPassword === this.repeatPassword
    ) {
      this.userService
        .changeUserPassword({
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
          repeatPassword: this.repeatPassword,
        })
        .then(
          () => {
            this.currentPassword = ""
            this.repeatPassword = ""
            this.newPassword = ""
            this.PWC = false;
            this.msg = this.translate.instant("changepassword.eightchars")
            this.toastrService.show(
              "",
              this.translate.instant(
                "shared.settings.successfullyChangedPassword"
              ),
              {
                status: "success",
              }
            );
          },
          (error) => {
            if (error.includes("Error Code: 403")) {
              this.toastrService.show(
                "",
                this.translate.instant(
                  "shared.settings.incorrectCurrentPassword"
                ),
                {
                  status: "danger",
                }
              );
            } else {
              this.toastrService.show(
                "",
                this.translate.instant("common.error.anUnknownErrorOccured"),
                {
                  status: "danger",
                }
              );
            }

            console.log(error);
          }
        );
    }
  }
}
