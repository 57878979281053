import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Md5 } from "ts-md5/dist/md5";
import { KeycloakService } from 'keycloak-angular';

/**
 * Todo add Hook to LoadingService
 */

@Injectable({
  providedIn: "root",
})
export class UserManagementService {
  private apiUrl: string = environment.apiUrl;
  private keycloakUrl: string = environment.keycloakUrl

  constructor(private http: HttpClient,protected readonly keycloak: KeycloakService    ) {}
  imitateAccount(id){
    const params = new HttpParams().set('newUserObj', id)
    
    this.http
    .post<any>(this.keycloakUrl+"/admin/realms/"+environment.keycloakRealm+"/users/"+id+"/impersonation", { }, {observe: "response" as 'body', withCredentials: true})
    .subscribe((response) => {
      //localStorage.setItem('token', response['access_token'])
      window.location.reload();
    
    })
  }
  addUserAccount(newUserObj) {
    const params = new HttpParams().set("newUserObj", newUserObj);

    return this.http
      .post<any>(this.apiUrl + "/usermanagement/", { params })
      .toPromise();
  }

  editUserAccount(userID, modifyUserObj) {
    const params = new HttpParams().set("modifyUserObj", modifyUserObj);

    return this.http
      .put<any>(this.apiUrl + "/usermanagement/" + userID, { params })
      .toPromise();
  }

  editPermissions(userID, permissionObj) {
    const params = new HttpParams().set("permissionObj", permissionObj);

    return this.http
      .put<any>(this.apiUrl + "/usermanagement/permissions/" + userID, {
        params,
      })
      .toPromise();
  }
  suspendUserAccount(userID){
    return this.http
      .put<any>(this.apiUrl + "/usermanagement/" + userID + "/suspend", {})
      .toPromise();
  }
  deleteUserAccount(userID) {
    return this.http
      .delete<any>(this.apiUrl + "/usermanagement/" + userID)
      .toPromise();
  }

  changeUserPassword(newPasswordObj) {
    const params = {
      currentPassword: newPasswordObj.currentPassword,
      newPassword: newPasswordObj.newPassword,
    };

    return this.http
      .put<any>(this.apiUrl + "/user/update", { params })
      .toPromise();
  }
}
