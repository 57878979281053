import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Md5 } from "ts-md5/dist/md5";

@Component({
  selector: 'app-confirm-with-password-dialog',
  templateUrl: './confirm-with-password-dialog.component.html',
  styleUrls: ['./confirm-with-password-dialog.component.scss']
})
export class ConfirmWithPasswordDialogComponent implements OnInit {
  public password: string;

  public todo: Boolean = false;
  constructor(
    protected ref: NbDialogRef<ConfirmWithPasswordDialogComponent>) { }

  ngOnInit(): void {
  }

  handleConfirm(): void {
    if (this.password) {
      this.ref.close(this.password) // TODO durch sha256 ersetzen
    }
  }

  close(): void {
    this.ref.close();
  }
}
