import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "./header/header.component";
import {
  NbActionsModule,
  NbUserModule,
  NbIconModule,
  NbContextMenuModule,
  NbMenuModule,
  NbCardModule,
  NbButtonModule,
  NbSidebarModule,
  NbSelectModule,
  NbInputModule,
  NbCheckboxModule,
} from "@nebular/theme";
import { FormsModule } from "@angular/forms";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { SettingsComponent } from "./pages/settings/settings.component";
import { TranslateModule } from "@ngx-translate/core";
import { SlideshowModule } from "ng-simple-slideshow";
import { ConfirmWithPasswordDialogComponent } from "./dialogs/confirm-with-password-dialog/confirm-with-password-dialog.component";
import { SlideshowDialogComponent } from "./dialogs/slideshow-dialog/slideshow-dialog.component";
import { CatalogOfMeasuresDialogComponent } from "./dialogs/catalog-of-measures-dialog/catalog-of-measures-dialog.component";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { RomanizePipe } from "./core/pipes/romanize.pipe";
import { ReportDialogComponent } from "./dialogs/report-dialog/report-dialog.component";
import { EvaluationDialogComponent } from './dialogs/evaluation-dialog/evaluation-dialog.component';

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    BreadcrumbComponent,
    SettingsComponent,
    ConfirmWithPasswordDialogComponent,
    SlideshowDialogComponent,
    CatalogOfMeasuresDialogComponent,
    RomanizePipe,
    ReportDialogComponent,
    EvaluationDialogComponent,
  ],
  providers: [RomanizePipe],
  imports: [
    NbButtonModule,
    CommonModule,
    NbActionsModule,
    NbUserModule,
    NbIconModule,
    NbSelectModule,
    NbContextMenuModule,
    NbCheckboxModule,
    NbMenuModule,
    NbCardModule,
    PdfViewerModule,
    NbSidebarModule,
    NbEvaIconsModule,
    TranslateModule,
    SlideshowModule,
    FormsModule,
    NbInputModule,
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    BreadcrumbComponent,
    RomanizePipe,
  ],
})
export class SharedModule {}
