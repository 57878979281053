import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NB_WINDOW, NbMenuService, NbSidebarService } from "@nebular/theme";
import { filter, map } from "rxjs/operators";
import { AuthenticationService } from "src/app/shared/core/services/authentication.service";
import { TranslateService } from "@ngx-translate/core";
import { CustomerDocumentationService } from "../core/services/customer-documentation.service";
import { KeycloakService } from "keycloak-angular";

@Component({
  selector: "shared-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  userInfo;
  currentLanguage;
  logoURL;
  adminBadgeVisible;
  profileItems;
  public auditMode: boolean = false;

  constructor(
    private nbMenuService: NbMenuService,
    @Inject(NB_WINDOW) private window,
    private sidebarService: NbSidebarService,
    private auth: AuthenticationService,
    private translate: TranslateService,
    private customerDocumentationService: CustomerDocumentationService,
    private router: Router,
    private keycloak: KeycloakService
  ) {
    this.currentLanguage =
      translate.currentLang === "en" ? "gb" : translate.currentLang;
  }
  public kcinfo: any;

  ngOnInit() {
    this.userInfo = JSON.parse(localStorage.getItem("extra"));

    if (this.userInfo.admin) {
      this.logoURL = "/admin/dashboard";

      this.adminBadgeVisible = true;

      this.profileItems = [
        {
          title: this.translate.instant("shared.settings.settings"),
          icon: "settings-2",
          link: "/admin/settings",
        },
        {
          title: this.translate.instant("shared.logout.logout"),
          icon: "log-out-outline",
          link: "logout",
        },
      ];
    } else {
      this.customerDocumentationService.getCustomerData().subscribe(
        (data) => {
          if(data.auditMode !== undefined){
            this.auditMode = data.auditMode
            this.customerDocumentationService.setAuditMode(data.auditMode)
          }
        },
        (error) => {
          console.log(error);
        }
      );
      this.logoURL = "/customer/startpage";

      this.adminBadgeVisible = false;

      this.profileItems = [
        {
          title: this.translate.instant("shared.settings.settings"),
          icon: "settings-2",
          link: "/customer/settings",
        },
        {
          title: this.translate.instant("shared.logout.logout"),
          icon: "log-out-outline",
          link: "logout",
        },
      ];
    }

    this.nbMenuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === "my-context-menu"),
        map(({ item: { link } }) => link)
      )
      .subscribe((link) => {
        if (link == "logout") {
          if(this.keycloak.isLoggedIn){
            this.keycloak.logout();
          }
          this.logout();
        }
      });
  }

  setLangEnglish() {
    this.translate.use("en");
    this.currentLanguage = "gb";
    localStorage.setItem("lang", "en");

    this.router.navigate([this.router.url]);
    this.window.location = this.router.url;
  }

  setLangGerman() {
    this.translate.use("de");
    this.currentLanguage = "de";
    localStorage.setItem("lang", "de");

    this.router.navigate([this.router.url]);
    this.window.location = this.router.url;
  }

  navigateHome() {
    this.router.navigate([this.logoURL]);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");

    return false;
  }

  logout() {
    this.auth.logout();
  }
}
