<div>
  <nb-layout>
    <nb-layout-column class="login-bg flex-cc">
      <nb-card [style.padding]="'20px'" [nbSpinner]="loading" nbSpinnerStatus="basic">
        <!--
        <div class="login-wrapper">
          <form [formGroup]="loginForm" (ngSubmit)="submit()" class="login">
            <section class="title">
              <h3 [style.text-align]="'center'" class="welcome">DocMaH</h3>
            </section>
            <br />
            <div class="login-group">
              <div class="form-group">
                <label class="d-none" for="username">Username</label>
                <input nbInput fullWidth type="text" id="username" name="username" clrInput
                  status="{{usernameInputStatus}}" placeholder="Username" formControlName="username" />
              </div>
              <div class="form-group">
                <label class="d-none" for="password">Password</label>
                <input nbInput fullWidth type="password" id="password" name="password" clrPassword
                  status="{{passwordInputStatus}}" placeholder="Password" formControlName="password" />
              </div>
              <button nbButton fullWidth type="submit" size="small" status="primary">
                Log In
              </button>
            </div>
          </form>
        </div>
        -->
      </nb-card>
    </nb-layout-column>
    <nb-layout-footer fixed>© Matuszak Hygiene - v{{currentAppVersion}}</nb-layout-footer>
  </nb-layout>
</div>