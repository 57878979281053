<router-outlet></router-outlet>
<div *ngIf="isLoading | async" class="overlay">
    <div class="container">
        <div class="row">
            <div id="loader">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="loading"></div>
            </div>
        </div>
    </div>
</div>

