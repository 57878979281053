<nb-card>
  <nb-card-header>
    {{ "common.label.confirmWithPassword" | translate }}
  </nb-card-header>
  <nb-card-body>
    <div [style.display]="'flex'">
      <p [style.flex]="'1'" *ngIf="!todo">
          {{ "common.disclaimers.password" | translate }}
      </p>
      <p [style.flex]="'1'" *ngIf="todo">
        {{ "common.disclaimers.todos" | translate }}
      </p>
    </div>
    <div [style.display]="'flex'" [style.width]="'100%'" [style.text-align]="'center'">
      <p [style.flex]="'1'">
        <b>{{ "common.label.password" | translate }}</b>
      </p>
    </div>
    <div [style.display]="'flex'" [style.width]="'100%'" [style.text-align]="'center'">
      <div [style.flex]="'1'">
        <input nbInput [(ngModel)]="password" type="password" [style.height]="'34.5pt'"
          status="{{ password?.length < 1 ? 'danger' : 'basic' }}"
          placeholder="{{ 'common.label.password' | translate }}"
          class="full-width size-medium status-basic shape-rectangle" />
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer [style.text-align]="'center'">
    <button nbButton status="success" class="mr-1" (click)="handleConfirm()">
      {{ "common.label.confirm" | translate }}
    </button>
    <button nbButton status="primary" (click)="close()">
      {{ "common.label.cancel" | translate }}
    </button>
  </nb-card-footer>
</nb-card>