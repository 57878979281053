import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "../core/services/navigation.service";
import { CustomerDocumentationService } from "src/app/shared/core/services/customer-documentation.service";
import { environment } from "src/environments/environment";
import  version from "../../../../package.json";

@Component({
  selector: "shared-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  items;
  selectedObject = JSON.parse(localStorage.getItem("selectedObject"));
  public weAboutUsPDFs: any;
  public currentAppVersion: string = version.version;
  public auditMode: boolean = false;
  public apiUrl: string = environment.apiUrl;
  constructor(private translate: TranslateService,
              private NavService: NavigationService,
              public customerDocumentationService: CustomerDocumentationService,
              ) {}

  ngOnInit() {
    
    const user = JSON.parse(localStorage.getItem("extra"));
    if (user.admin) {
      this.items = [
        {
          title: this.translate.instant("admin.dashboard.dashboard"),
          link: "/admin/dashboard",
          icon: "layout-outline",
        },
        {
          title: this.translate.instant("admin.masterData"),
          icon: "book-outline",
          children: [
            {
              title: this.translate.instant("admin.customers.customers"),
              icon: "people-outline",
              link: "/admin/customers",
            },
            {
              title: this.translate.instant("admin.vermin.vermin"),
              icon: "crop-outline",
              link: "/admin/vermin",
            },
            {
              title: this.translate.instant(
                "customerViewer.preparationList.preparations"
              ),
              icon: "pricetags-outline",
              link: "/admin/preparations",
            },
            {
              title: this.translate.instant("admin.systems.systems"),
              icon: "hard-drive-outline",
              link: "/admin/systems",
            },
            {
              title: this.translate.instant(
                "customerViewer.dashboard.employerProfiles"
              ),
              icon: "person-outline",
              link: "/admin/employee-profiles",
            },
            {
              title: this.translate.instant("common.label.certificates"),
              icon: "layers-outline",
              link: "/admin/certificates",
            },
            {
              title: this.translate.instant("common.label.aboutUs"),
              icon: "smiling-face-outline",
              link: "/admin/we-about-us",
            },
          ],
        },
        // {
        //   title: this.translate.instant("admin.texts.textTemplates"),
        //   icon: "text-outline",
        //   link: "/admin/texttemplates-database",
        // },
        {
          title: this.translate.instant("admin.userManagement.userManagement"),
          link: "/admin/admin-management",
          icon: "person-done-outline",
        },
      ];
    } else {
      this.NavService.selectedObject$.subscribe((data) => {
        if(typeof(data) === "number" && data === 1){
          let branchId = localStorage.getItem("selectedObjId")
          this.customerDocumentationService.getWeAboutUsPDFs().subscribe(
            (data2) => {
              this.weAboutUsPDFs = data2;
              let weAboutUsurl = "d";
              if(this.translate.currentLang === "de"){
                weAboutUsurl = this.weAboutUsPDFs?.pdfGer;
              }else{
                weAboutUsurl = this.weAboutUsPDFs?.pdfEng;
              }

              this.items = [
                {
                  title: this.translate.instant("shared.startpage"),
                  link: "/customer/startpage",
                  icon: "home-outline",
                },
                {
                  title: this.translate.instant("customerViewer.documentation"),
                  link: "/customer/customer-documentation",
                  icon: "archive-outline",
                },
                {
                  title: this.translate.instant("admin.dashboard.dashboard"),
                  link: "/customer/dashboard",
                  icon: "layout-outline",
                  show: false,
                  children: [
                    {
                      title: this.translate.instant("customerViewer.backtoSelectedObject"),
                      link: "/customer/customer-documentation",
                      icon: ""
                    },
                    /*
                    {
                      title: this.translate.instant(
                        "customerViewer.dashboard.terminplan"
                      ),
                      icon: "calendar-outline",
                      link: "/customer/events/",
                    },
                    */
                    {
                      title: this.translate.instant("admin.plans.controlInterval"),
                      icon: "calendar-outline",
                      link: "/customer/control-interval/",
                    },
                    {
                      title: this.translate.instant(
                        "customerViewer.dashboard.maßnahmenkatalog"
                      ),
                      icon: "pantone-outline",
                      link: "/customer/catalogs-of-measures/",
                    },
                    {
                      title: this.translate.instant(
                        "customerViewer.preparationList.preparationList"
                      ),
                      icon: "pricetags-outline",
                      link: "/customer/preparation-list/",
                    },
                    {
                      title: this.translate.instant(
                        "customerViewer.dashboard.meldebuch"
                      ),
                      icon: "book-outline",
                      link: "/customer/logbook/",
                    },
                    {
                      title: this.translate.instant(
                        "customerViewer.dashboard.employerProfiles"
                      ),
                      icon: "person-outline",
                      link: "/customer/employee-profiles/",
                    },
                    {
                      title: this.translate.instant(
                        "customerViewer.weAboutUs.weAboutUs"
                      ),
                      icon: "smiling-face-outline",
                      url: "https://matuszak-hygiene.de/ueber-uns/",
                      target: "_blank"
                    },
                  ],
                },
              ];


            },
            (error) => {
              console.log(error);
            }
          );

        }else {
          this.items = [
            {
              title: this.translate.instant("shared.startpage"),
              link: "/customer/startpage",
              icon: "home-outline",
            },
            {
              title: this.translate.instant("customerViewer.documentation"),
              link: "/customer/customer-documentation",
              icon: "archive-outline",
            },
          ];
        }
      })

    }
  }
}
