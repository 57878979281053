import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthenticationService } from "../services/authentication.service";
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  authenticated: any;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    protected readonly keycloak: KeycloakService
  ) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    const userData = JSON.parse(localStorage.getItem("extra"));

    if(this.keycloak.isLoggedIn){
      switch (route.routeConfig.path) {
        case "admin":
          if(this.keycloak.isUserInRole("administrator")){
            return true;
          }else{
            this.router.navigate(["auth"], { queryParams: { returnUrl: state.url } });
            return false;
          }
        case "customer":
          if(!this.keycloak.isUserInRole("administrator")){
            return true;
          }else{
            this.router.navigate(["auth"], { queryParams: { returnUrl: state.url } });
            return false;
          }
      }
    }else{
      if (currentUser) {
        switch (route.routeConfig.path) {
          case "admin":
            if (userData.admin) {
              return true;
            } else {
              this.router.navigate(["auth"], { queryParams: { returnUrl: state.url } });
              return false;
            }
          case "customer":
            if (!userData.admin) {
              return true;
            } else {
              this.router.navigate(["auth"], { queryParams: { returnUrl: state.url } });
              return false;
            }
        }
      } else {
          this.router.navigate(["auth"], { queryParams: { returnUrl: state.url } });
        return false;
      }
    }
  }
}