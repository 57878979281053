import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
/**
 * Todo add Hook to LoadingService
 */

@Injectable({
  providedIn: "root",
})
export class CatalogOfMeasuresService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  addCatalogOfMeasures(newCatalog): Promise<any> {
    const params = new HttpParams().set("newCatalog", newCatalog);

    return this.http
      .post<any>(this.apiUrl + "/branch/catalog-of-measures/", { params })
      .toPromise();
  }

  signCatalogOfMeasures(signData: any): Promise<any> {
    const params = new HttpParams().set("signData", signData);

    return this.http
      .post<any>(this.apiUrl + "/branch/catalog-of-measures/sign/", { params })
      .toPromise();
  }

  deleteCatalogOfMeasures(deleteCatalogObj): Promise<any> {
    const params = new HttpParams().set(
      "deleteCatalogObj",
      JSON.stringify(deleteCatalogObj)
    );

    return this.http
      .delete<any>(this.apiUrl + "/branch/catalog-of-measures/", { params })
      .toPromise();
  }
}
