import { Component, OnInit, ViewChild } from "@angular/core";

@Component({
  selector: "app-slideshow-dialog",
  templateUrl: "./slideshow-dialog.component.html",
  styleUrls: ["./slideshow-dialog.component.scss"],
})
export class SlideshowDialogComponent implements OnInit {
  public todoImgUrlArray: any = [];
  public currentSlideshowIndex: number;

  @ViewChild("slideshow") slideshow: any;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (
      this.currentSlideshowIndex !== undefined &&
      this.currentSlideshowIndex > 0
    ) {
      this.slideshow.goToSlide(this.currentSlideshowIndex);
    }
  }
}
