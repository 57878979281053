import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs/Observable";
/**
 * Todo add Hook to LoadingService
 */

@Injectable()
export class CustomerDocumentationService {
  private apiUrl = environment.apiUrl;
  public auditMode: boolean = false;
  constructor(private http: HttpClient) {}
  getAuditMode(): any {
    return localStorage.getItem("auditMode")
  }
  setAuditMode(mode):any {
    localStorage.setItem("auditMode",mode)
  }
  getCustomerData(): Observable<any> {
    return this.http.get<any>(this.apiUrl + "/documentation/customer/data");
  }
  getCustomer(): Observable<any> {
    return this.http.get<any>(this.apiUrl + "/documentation/customer");
  }

  getBranch(id: string, planRevObj = undefined): Observable<any> {
    const params = new HttpParams().set(
      "planRevObj",
      JSON.stringify(planRevObj)
    );

    return this.http.get<any>(this.apiUrl + "/documentation/branch/" + id, {
      params,
    });
  }

  getPreparationList(): Observable<any> {
    return this.http.get<any>(this.apiUrl + "/documentation/preparation-list/");
  }

  getAllActivities(): Observable<any> {
    return this.http.get<any>(this.apiUrl + "/documentation/activities/");
  }

  getWeAboutUsPDFs(): Observable<any> {
    return this.http.get<any>(this.apiUrl + "/documentation/we-about-us/");
  }

  getAllEvents(): Observable<any> {
    return this.http.get<any>(this.apiUrl + "/documentation/events/");
  }

  getCertificates(): Observable<any> {
    return this.http.get<any>(this.apiUrl + "/documentation/certificates/");
  }

  getEmployeeProfiles(): Observable<any> {
    return this.http.get<any>(
      this.apiUrl + "/documentation/employee-profiles/"
    );
  }

  getActivitiesByBranch(branchID: string): Observable<any> {
    return this.http.get<any>(
      this.apiUrl + "/documentation/activities/branch/" + branchID
    );
  }

  getEventsByBranch(branchID: string): Observable<any> {
    return this.http.get<any>(
      this.apiUrl + "/documentation/events/branch/" + branchID
    );
  }

  getWorklogs(branchID): Observable<any> {
    return this.http.get<any>(
      this.apiUrl + "/documentation/worklogs/" + branchID
    );
  }

  getControlIntervals(): Observable<any> {
    return this.http.get<any>(this.apiUrl + "/documentation/control-intervals");
  }

  signCatalogOfMeasures(signCatalogObj): Promise<any> {
    const params = new HttpParams().set("signCatalogObj", signCatalogObj);

    return this.http
      .post<any>(this.apiUrl + "/documentation/catalog-of-measures/sign", {
        params,
      })
      .toPromise();
  }
  signEvaluations(signReportObj,reportId): Promise<any> {
    const params = new HttpParams().set("signReportObj", signReportObj);

    return this.http
      .post<any>(this.apiUrl + "/documentation/evaluation/sign/"+reportId, {
        params,
      })
      .toPromise();
  }
  signReports(signReportObj,reportId): Promise<any> {
    const params = new HttpParams().set("signReportObj", signReportObj);

    return this.http
      .post<any>(this.apiUrl + "/documentation/report/sign/"+reportId, {
        params,
      })
      .toPromise();
  }

  signPlan(signPlanObj): Promise<any> {
    const params = new HttpParams().set("signPlanObj", signPlanObj);

    return this.http
      .post<any>(this.apiUrl + "/documentation/plan/sign", {
        params,
      })
      .toPromise();
  }

  signTODO(signTodoObj): Promise<any> {
    const params = new HttpParams().set("signTodoObj", signTodoObj);

    return this.http
      .post<any>(this.apiUrl + "/documentation/todo/sign", { params })
      .toPromise();
  }

  updateBranchTodos(branchID: string, todos): Promise<any> {
    const params = new HttpParams().set("todos", todos);

    return this.http
      .put<any>(this.apiUrl + "/documentation/todo/" + branchID, { params })
      .toPromise();
  }

  signChecklist(signData: any): Promise<any> {
    const params = new HttpParams().set("signData", signData);

    return this.http
      .post<any>(
        this.apiUrl + "/documentation/checklist/sign/" + signData.checklistID,
        { params }
      )
      .toPromise();
  }
}
