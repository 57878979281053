import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
/**
 * Todo add Hook to LoadingService
 */

@Injectable({
  providedIn: 'root'
})
export class EvaluationService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  addEvaluation(newEvaluation): Promise<any> {
    const params = new HttpParams().set("newEvaluation", newEvaluation);

    return this.http
      .post<any>(this.apiUrl + "/branch/evaluation/", { params })
      .toPromise();
  }
  signEvaluation(signReportObj,reportId): Promise<any> {
    const params = new HttpParams().set("signReportObj", signReportObj);

    return this.http
      .post<any>(this.apiUrl + "/branch/evaluation/sign/"+reportId, {
        params,
      })
      .toPromise();
  }

  deleteEvaluation(deleteEvaluationObj): Promise<any> {
    const params = new HttpParams().set(
      "deleteEvaluationObj",
      JSON.stringify(deleteEvaluationObj)
    );

    return this.http
      .delete<any>(this.apiUrl + "/branch/evaluation/", { params })
      .toPromise();
  }
}
