<div class="flex-justify">
  <div class="header-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a [style.cursor]="'pointer'" (click)="navigateHome()">
      <div class="logo"></div>
    </a>
    <a [style.cursor]="'pointer'" (click)="navigateHome()" [style.text-decoration]="'none'">
      <div class="adminBadge">
        {{
          adminBadgeVisible
            ? ("admin.administration" | translate)
            : ("customerViewer.customerViewer" | translate) 
        }}
        {{ auditMode 
            ? ("| Audit-Trail")
            : ("")
          }}
      </div>
    </a>
  </div>
  <nb-actions size="small">
    <nb-action>
      <button [style.border]="'none'" [style.background-color]="'unset'" [style.margin]="'2px'" nbButton
              class="langSelector flag-icon flag-icon-de" (click)="setLangGerman()"></button>
      <button [style.border]="'none'" [style.background-color]="'unset'" [style.margin]="'2px'" nbButton
        class="langSelector flag-icon flag-icon-gb" (click)="setLangEnglish()"></button>
    </nb-action>
    <nb-action>
      <a href="tel:067011364" target="_blank">
        <nb-icon icon="phone-call-outline" pack="eva"></nb-icon><span>06701 / 1364</span>
      </a>
    </nb-action>
    <nb-action>
      <a href="mailto:info@matuszak-hygiene.de" target="_blank">
        <nb-icon icon="email-outline" pack="eva"></nb-icon><span>info@matuszak-hygiene.de</span>
      </a>
    </nb-action>
    <nb-action>
      <nb-user class="userHeader" name="{{ userInfo.prename }} {{ userInfo.lastname }}" title="{{ userInfo.company }}"
        [nbContextMenu]="profileItems" nbContextMenuTag="my-context-menu">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>