import { Injectable, Injector } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { retry, catchError } from "rxjs/operators";
import { Observable } from "rxjs/internal/Observable";
import { AuthenticationService } from "src/app/shared/core/services/authentication.service";
import { throwError } from "rxjs";
import { environment } from "../../../../environments/environment";
import "rxjs/add/operator/do";
import { LoaderService } from "../services/loader.service";
/**
 * Todo add Hook to LoadingService
 */

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  lService: any
  constructor(
    private auth: AuthenticationService,
  ) {

  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        authorization: this.auth.createAuthHeaderValue(),
      },
    });
    return next
      .handle(request)
      .do((ev: HttpEvent<any>) => {
        if (ev instanceof HttpResponse) {
          if (!environment.production) {
            //console.log("Response:", ev);
          }
          //console.log("Response:", ev);
          
          if (ev.status === 401) {
            this.auth.logout();
          }
        }
      })
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = "";
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }
          return throwError(errorMessage);
        })
      );
  }
}
