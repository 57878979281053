import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { NbToastrService } from "@nebular/theme";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "../shared/core/services/authentication.service";
import { first } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import  version from "../../../package.json";
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading: boolean = false;
  submitted: boolean = false;
  returnUrl: string;

  public currentAppVersion: string = version.version;

  usernameInputStatus: string = "basic";
  passwordInputStatus: string = "basic";

  constructor(
    public translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: NbToastrService,
    private keycloak: KeycloakService
  ) {
    this.loginForm = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
    });

    this.returnUrl = this.route.snapshot.queryParams.returnUrl || "/";
  }

  ngOnInit() {
    if(this.keycloak.isLoggedIn){
      if(this.keycloak.isUserInRole("administrator")){
        this.router.navigate(["/admin/dashboard"]);
      }else{
        this.router.navigate(["/customer/startpage"]);
      }
    }else{
      if (
        this.authenticationService.currentUserValue &&
        localStorage.getItem("extra")
      ) {
        const user = JSON.parse(localStorage.getItem("extra"));
        if (user.admin) {
          this.router.navigate(["/admin/dashboard"]);
        } else {
          this.router.navigate(["/customer/startpage"]);
        }
      }
    }
  }

  get f() {
    return this.loginForm.controls;
  }

  showToast(position, status, message) {
    this.toastrService.show("", message, {
      position,
      status,
    });
  }

  submit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      this.usernameInputStatus =
        this.f.username.value.length < 1 ? "danger" : "basic";
      this.passwordInputStatus =
        this.f.password.value.length < 1 ? "danger" : "basic";

      return;
    }

    this.usernameInputStatus = "basic";
    this.passwordInputStatus = "basic";

    this.loading = true;

    this.authenticationService
      .login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data.admin) {
            this.router.navigate(["/admin/dashboard"]);
          } else {
            this.router.navigate(["/customer/startpage"]);
          }

          this.loading = false;
        },
        (error) => {
          if (
            error.includes("Error Code: 405") ||
            error.includes("Error Code: 403")
          ) {
            this.showToast(
              "top-right",
              "danger",
              this.translate.instant("common.login.wrongCredentials")
            );
          } else {
            this.showToast(
              "top-right",
              "danger",
              this.translate.instant("common.error.anUnknownErrorOccured")
            );
          }

          this.loading = false;
        }
      );
  }
}
