import { Component, OnInit } from "@angular/core";
import { NbDialogRef, NbToastrService, NbDialogService } from "@nebular/theme";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmWithPasswordDialogComponent } from "../confirm-with-password-dialog/confirm-with-password-dialog.component";
import { CatalogOfMeasuresService } from "../../core/services/catalog-of-measures.service";
import { CustomerDocumentationService } from "../../core/services/customer-documentation.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-catalog-of-measures-dialog",
  templateUrl: "./catalog-of-measures-dialog.component.html",
  styleUrls: ["./catalog-of-measures-dialog.component.scss"],
})
export class CatalogOfMeasuresDialogComponent implements OnInit {
  public isAdminView: boolean;

  public catalogOfMeasures: any;
  public branchID: string;
  public verminType: string;
  public pdfURL: string;

  public permissionWrite: boolean = false;

  private apiUrl: string = environment.apiUrl;

  constructor(
    public translate: TranslateService,
    protected ref: NbDialogRef<CatalogOfMeasuresDialogComponent>,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private catalogOfMeasuresService: CatalogOfMeasuresService,
    private documentationService: CustomerDocumentationService
  ) {}

  ngOnInit(): void {
    this.pdfURL =
      this.apiUrl +
      "/" +
      (this.translate.currentLang === "de"
        ? this.catalogOfMeasures.pdfGer
        : this.catalogOfMeasures.pdfEng);
  }

  toggleMatuszakSignature(event): void {
    event.preventDefault();

    this.dialogService
      .open(ConfirmWithPasswordDialogComponent, {
        context: {},
      })
      .onClose.subscribe((hashedUserPW) => {
        if (hashedUserPW) {
          this.catalogOfMeasuresService
            .signCatalogOfMeasures({
              hashedUserPW,
              branchID: this.branchID,
              verminType: this.verminType,
            })
            .then(
              () => {
                this.ref.close(true);

                this.toastrService.show(
                  "",
                  this.translate.instant(
                    "customerViewer.dashboard.maßnahmenSuccessfullyModified"
                  ),
                  {
                    status: "success",
                  }
                );
              },
              (error) => {
                this.toastrService.show(
                  "",
                  error.includes("409")
                    ? this.translate.instant("common.login.wrongCredentials")
                    : this.translate.instant(
                        "common.error.anUnknownErrorOccured"
                      ),
                  {
                    status: "danger",
                  }
                );
              }
            );
        }
      });
  }

  toggleCustomerSignature(event): void {
    event.preventDefault();

    this.dialogService
      .open(ConfirmWithPasswordDialogComponent, {
        context: {},
      })
      .onClose.subscribe((hashedUserPW) => {
        if (hashedUserPW) {
          this.documentationService
            .signCatalogOfMeasures({
              hashedUserPW,
              branchID: this.branchID,
              verminType: this.verminType,
            })
            .then(
              () => {
                this.ref.close(false);

                this.toastrService.show(
                  "",
                  this.translate.instant(
                    "customerViewer.dashboard.maßnahmenSuccessfullyModified"
                  ),
                  {
                    status: "success",
                  }
                );
              },
              (error) => {
                this.toastrService.show(
                  "",
                  error.includes("409")
                    ? this.translate.instant("common.login.wrongCredentials")
                    : this.translate.instant(
                        "common.error.anUnknownErrorOccured"
                      ),
                  {
                    status: "danger",
                  }
                );
              }
            );
        }
      });
  }

  close(): void {
    this.ref.close();
  }
}
