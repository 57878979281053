import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { LoaderService } from "./shared/core/services/loader.service";
import { KeycloakEventType, KeycloakService } from "keycloak-angular";
import { KeycloakProfile } from "keycloak-js";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})

export class AppComponent {
  public isLoggedIn = false;
  public userProfile: KeycloakProfile2 | null = null;

  title = "DocMaH";
  isLoading: Subject<boolean> = this.loaderService.isLoading;
  ready = false;


  constructor(private readonly keycloak: KeycloakService, public translate: TranslateService, private loaderService: LoaderService) {
    const lang = localStorage.getItem("lang");

    translate.addLangs(["en", "de"]);
    translate.setDefaultLang("de");
    const browserLang = translate.getBrowserLang();
    translate.use(
      lang !== null && lang !== undefined
        ? lang
        : browserLang.match(/en|de/)
        ? browserLang
        : "de"
    );
  }
  public async ngOnInit() {
    this.isLoggedIn = await this.keycloak.isLoggedIn();
    
    if (this.isLoggedIn) {
      this.userProfile = await this.keycloak.loadUserProfile();
    }else{
      this.keycloak.login()
    }
    if(this.isLoggedIn){
      const extra = {
        prename: this.userProfile.firstName,
        lastname: this.userProfile.lastName,
        company: this.userProfile.attributes.company,
        admin: this.keycloak.isUserInRole("administrator"),
        phone: "",
        mail: this.userProfile.email
      };
      console.log(window.location.origin + '/assets/silent-check-sso.html')
      //localStorage.setItem("token", JSON.stringify(user.token));
      localStorage.setItem("extra", JSON.stringify(extra));
      this.keycloak.keycloakEvents$.subscribe({
        next: (e) => {
          console.log(e)
          if(e.type === KeycloakEventType.OnAuthLogout){
            this.keycloak.logout()
          }
          if(e.type === KeycloakEventType.OnAuthRefreshError){
            this.keycloak.logout()
            this.keycloak.login()
          }
          if(e.type === KeycloakEventType.OnAuthError){
            this.keycloak.logout()
            this.keycloak.login()
          }
          if(e.type === KeycloakEventType.OnTokenExpired){
            this.keycloak.updateToken(20)
            console.log("Refreshing Token")
          }
        }
      })
    }
  }

}

export interface KeycloakProfile2 extends KeycloakProfile{
	attributes?: any
}
