<nb-card [class]="!userInfo.admin ? 'bg' : null">
  <nb-card-header class="umHeader">
    {{ "shared.settings.settings" | translate }}
  </nb-card-header>

  <nb-card-body>
    <div class="profileWrapper">
      <div class="column">
        <div class="userProfile">
          <nb-user name="{{ userInfo.prename }} {{ userInfo.lastname }}" title="{{ userInfo.company }}"></nb-user>
        </div>
      </div>
      <div class="column">
        <div class="changePassword">
          <input nbInput [(ngModel)]="currentPassword" (keyup)="change()" type="password" name="password"
            status="{{ currentPassword?.length < 1 ? 'danger' : 'basic' }}"
            placeholder="{{ 'common.label.currentPassword' | translate }}"
            class="full-width size-medium status-basic shape-rectangle" />
          <br /><br />
          <input nbInput [(ngModel)]="newPassword" (keyup)="change()" type="password" name="password"
            status="{{ newPassword?.length < 8 ||  newPassword !== repeatPassword ? 'danger' : 'basic' }}"
            placeholder="{{ 'common.label.newPassword' | translate }}"
            class="full-width size-medium status-basic shape-rectangle" /> <br /><br />
          <input nbInput [(ngModel)]="repeatPassword" (keyup)="change()" type="password" name="password"
            status="{{ repeatPassword?.length < 8 || newPassword !== repeatPassword ? 'danger' : 'basic' }}"
            placeholder="{{ 'common.label.repeatPassword' | translate }}"
            class="full-width size-medium status-basic shape-rectangle" />
          <br /><br />
          <div class="userProfile pwc" *ngIf="!PWC">
            {{ msg }}
          </div>
          <br />
          <input nbInput (click)="changeUserPassword()" type="button" *ngIf="PWC" class="size-medium status-basic shape-rectangle"
            value="{{ 'common.label.changePassword' | translate }}"
            class="appearance-filled full-width size-small status-primary shape-rectangle transitions" />
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>