import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./shared/core/guards/auth.guard";
import { LoginComponent } from "./login/login.component";

const routes: Routes = [
  {
    path: "admin",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: "customer",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./customer/customers.module").then((m) => m.CustomersModule),
  },
  {
    path: "auth",
    component: LoginComponent,
  },
  {
    path: "",
    redirectTo: "auth",
    pathMatch: "full",
  },
  { path: "**", redirectTo: "auth" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
