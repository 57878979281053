import { Component, OnInit } from "@angular/core";
import { NbDialogRef, NbToastrService, NbDialogService } from "@nebular/theme";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmWithPasswordDialogComponent } from "../confirm-with-password-dialog/confirm-with-password-dialog.component";
import { CustomerDocumentationService } from "../../core/services/customer-documentation.service";
import { environment } from "src/environments/environment";
import { ReportService } from "../../core/services/report.service";
@Component({
  selector: 'app-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.scss']
})
export class ReportDialogComponent implements OnInit {
    public isAdminView: boolean;

    public report: any;
    public branchID: string;
    public pdfURL: string;
    public pdf: any;
    public permissionWrite: boolean = false;

    private apiUrl: string = environment.apiUrl;

    constructor(    public translate: TranslateService,
      protected ref: NbDialogRef<ReportDialogComponent>,
      private toastrService: NbToastrService,
      private dialogService: NbDialogService,
      private reportService: ReportService,
      private documentationService: CustomerDocumentationService) { }

    ngOnInit(): void {
      if(this.report.customerSignature === undefined){
        this.report.customerSignature = []
        this.report.customerSignature.name = "";
        this.report.customerSignature.userID = "";
        this.report.customerSignature.signedAt = "";
      }
      if(this.report.matuszakSignature === undefined){
        this.report.matuszakSignature = []
        this.report.matuszakSignature.name = "";
        this.report.matuszakSignature.userID = "";
        this.report.matuszakSignature.signedAt = "";

      }
    }

    close(): void {
      this.ref.close();
    }

    toggleMatuszakSignature(event): void {
      event.preventDefault();
      
      this.dialogService
        .open(ConfirmWithPasswordDialogComponent, {
          context: {},
        })
        .onClose.subscribe((hashedUserPW) => {
          if (hashedUserPW) {
            this.reportService
              .signReport({
                hashedUserPW,
                branchID: this.branchID,
              }, this.report._id)
              .then(
                () => {
                  this.ref.close(true);
  
                  this.toastrService.show(
                    "",
                    this.translate.instant(
                      "customerViewer.dashboard.maßnahmenSuccessfullyModified"
                    ),
                    {
                      status: "success",
                    }
                  );
                },
                (error) => {
                  this.toastrService.show(
                    "",
                    error.includes("409")
                      ? this.translate.instant("common.login.wrongCredentials")
                      : this.translate.instant(
                          "common.error.anUnknownErrorOccured"
                        ),
                    {
                      status: "danger",
                    }
                  );
                }
              );
          }
        });
    }
  
    toggleCustomerSignature(event): void {
      event.preventDefault();
  
      this.dialogService
        .open(ConfirmWithPasswordDialogComponent, {
          context: {},
        })
        .onClose.subscribe((hashedUserPW) => {
          if (hashedUserPW) {
            this.documentationService
              .signReports({
                hashedUserPW,
                branchID: this.branchID,
              }, this.report._id)
              .then(
                () => {
                  this.ref.close(true);
  
                  this.toastrService.show(
                    "",
                    this.translate.instant(
                      "customerViewer.dashboard.maßnahmenSuccessfullyModified"
                    ),
                    {
                      status: "success",
                    }
                  );
                },
                (error) => {
                  this.toastrService.show(
                    "",
                    error.includes("409")
                      ? this.translate.instant("common.login.wrongCredentials")
                      : this.translate.instant(
                          "common.error.anUnknownErrorOccured"
                        ),
                    {
                      status: "danger",
                    }
                  );
                }
              );
          }
        });
    }
}
