<nb-card>
    <nb-card-header [style.display]="'flex'" [style.flex-direction]="'row'">
        {{ "customerViewer.reports.reports" | translate }}
      <div [style.margin-left]="'20pt'" [style.display]="'flex'">
        <span>
            {{ "admin.branch.customerSignature" | translate }}:
        </span>
        <div [style.margin-top]="'1.5pt'" [style.margin-left]="'3pt'" [style.margin-right]="'3pt'">
          <nb-checkbox [(ngModel)]="eval?.customerSignature.signedAt"
            (click)="isAdminView || !!eval?.customerSignature.signedAt || !permissionWrite ? undefined : toggleCustomerSignature($event)"
            [disabled]="isAdminView || !!eval?.customerSignature.signedAt || !permissionWrite">
          </nb-checkbox>
        </div>
        <span [style.color]="isAdminView || !!eval?.customerSignature.signedAt ? 'lightgrey' : 'white'"  [style.cursor]="isAdminView || !!eval?.customerSignature.signedAt || !permissionWrite ? 'default' : 'pointer'" (click)="isAdminView ||!!eval?.customerSignature.signedAt || !permissionWrite ? undefined : toggleCustomerSignature($event)">
          {{
            !!eval?.customerSignature.signedAt ?
            ("admin.branch.signedByC" | translate) + " " + eval?.customerSignature.name + " [" +
            (eval?.customerSignature.signedAt| date: "dd.MM.yyyy - HH:mm") + "]" :
            ("admin.branch.notSignedC" | translate)
          }}
        </span>

        <span [style.margin-left]="'10pt'" [style.margin-bottom]="'5pt'">
            {{ "admin.branch.matuszakSignature" | translate }}:
          </span>
          <div [style.margin-top]="'1.5pt'" [style.margin-left]="'3pt'" [style.margin-right]="'3pt'">
            <nb-checkbox [(ngModel)]="eval?.matuszakSignature.signedAt"
              (click)="!isAdminView || !!eval?.matuszakSignature.signedAt ? undefined : toggleMatuszakSignature($event)"
              [disabled]="!isAdminView || !!eval?.matuszakSignature.signedAt">
            </nb-checkbox>
          </div>
          <span [style.color]="!isAdminView || !!eval?.matuszakSignature.signedAt ? 'lightgrey' : 'white'" [style.cursor]="!isAdminView || !!eval?.matuszakSignature.signedAt ? 'default' : 'pointer'" (click)="!isAdminView || !!eval?.matuszakSignature.signedAt ? undefined : toggleMatuszakSignature($event)">
            {{
              !!eval?.matuszakSignature.signedAt ?
              ("admin.branch.signedByM" | translate) + " " + eval?.matuszakSignature.name + " [" +
              (eval?.matuszakSignature.signedAt| date: "dd.MM.yyyy - HH:mm") + "]" :
              ("admin.branch.notSignedM" | translate)
            }}
          </span>
        
      </div>
      <div
        [style.display]="'flex'"
        [style.align-items]="'center'"
        [style.margin-left]="'auto'"
      >
        <nb-icon
          (click)="close()"
          class="closeIcon"
          icon="close-outline"
          pack="eva"
        >
        </nb-icon>
      </div>
    </nb-card-header>
    <nb-card-body>
      <pdf-viewer [src]="pdfURL" [render-text]="true" style="display: block;"></pdf-viewer>
    </nb-card-body>
  </nb-card>