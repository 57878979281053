import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
/**
 * Todo add Hook to LoadingService
 */

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  editReport(newReport): Promise<any>{
    const params = new HttpParams().set("newReport", newReport);

    return this.http
      .post<any>(this.apiUrl + "/branch/report/edit", { params })
      .toPromise();
  }
  voidReport(newReport): Promise<any>{
    const params = new HttpParams().set("newReport", newReport);

    return this.http
      .post<any>(this.apiUrl + "/branch/report/void", { params })
      .toPromise();
  }
  addReport(newReport): Promise<any> {
    const params = new HttpParams().set("newReport", newReport);

    return this.http
      .post<any>(this.apiUrl + "/branch/report/", { params })
      .toPromise();
  }

  signReport(signReportObj,reportId): Promise<any> {
    const params = new HttpParams().set("signReportObj", signReportObj);

    return this.http
      .post<any>(this.apiUrl + "/branch/report/sign/"+reportId, {
        params,
      })
      .toPromise();
  }

  deleteReport(deleteReportObj): Promise<any> {
    const params = new HttpParams().set(
      "deleteReportObj",
      JSON.stringify(deleteReportObj)
    );

    return this.http
      .delete<any>(this.apiUrl + "/branch/report/", { params })
      .toPromise();
  }
}
