<nb-card>
  <nb-card-header [style.display]="'flex'" [style.flex-direction]="'row'">
    {{ "customerViewer.dashboard.maßnahmenkatalog" | translate }}
    <div [style.margin-left]="'20pt'" [style.display]="'flex'">
      <span>
          {{ "admin.branch.customerSignature" | translate }}:
      </span>
      <div [style.margin-top]="'1.5pt'" [style.margin-left]="'3pt'" [style.margin-right]="'3pt'">
        <nb-checkbox [(ngModel)]="catalogOfMeasures.customerSignature.signedAt"
          (click)="isAdminView || !!catalogOfMeasures.customerSignature.signedAt || !permissionWrite ? undefined : toggleCustomerSignature($event)"
          [disabled]="isAdminView || !!catalogOfMeasures.customerSignature.signedAt || !permissionWrite">
        </nb-checkbox>
      </div>
      <span [style.color]="isAdminView || !!catalogOfMeasures.customerSignature.signedAt ? 'lightgrey' : 'white'"  [style.cursor]="isAdminView || !!catalogOfMeasures.customerSignature.signedAt || !permissionWrite ? 'default' : 'pointer'" (click)="isAdminView ||!!catalogOfMeasures.customerSignature.signedAt || !permissionWrite ? undefined : toggleCustomerSignature($event)">
        {{
          !!catalogOfMeasures.customerSignature.signedAt ?
          ("admin.branch.signedByC" | translate) + " " + catalogOfMeasures.customerSignature.name + " [" +
          (catalogOfMeasures.customerSignature.signedAt| date: "dd.MM.yyyy - HH:mm") + "]" :
          ("admin.branch.notSignedC" | translate)
        }}
      </span>
      <span [style.margin-left]="'10pt'" [style.margin-bottom]="'5pt'">
        {{ "admin.branch.matuszakSignature" | translate }}:
      </span>
      <div [style.margin-top]="'1.5pt'" [style.margin-left]="'3pt'" [style.margin-right]="'3pt'">
        <nb-checkbox [(ngModel)]="catalogOfMeasures.matuszakSignature.signedAt"
          (click)="!isAdminView || !!catalogOfMeasures.matuszakSignature.signedAt ? undefined : toggleMatuszakSignature($event)"
          [disabled]="!isAdminView || !!catalogOfMeasures.matuszakSignature.signedAt">
        </nb-checkbox>
      </div>
      <span [style.color]="!isAdminView || !!catalogOfMeasures.matuszakSignature.signedAt ? 'lightgrey' : 'white'" [style.cursor]="!isAdminView || !!catalogOfMeasures.matuszakSignature.signedAt ? 'default' : 'pointer'" (click)="!isAdminView || !!catalogOfMeasures.matuszakSignature.signedAt ? undefined : toggleMatuszakSignature($event)">
        {{
          !!catalogOfMeasures.matuszakSignature.signedAt ?
          ("admin.branch.signedByM" | translate) + " " + catalogOfMeasures.matuszakSignature.name + " [" +
          (catalogOfMeasures.matuszakSignature.signedAt| date: "dd.MM.yyyy - HH:mm") + "]" :
          ("admin.branch.notSignedM" | translate)
        }}
      </span>
    </div>
    <div
      [style.display]="'flex'"
      [style.align-items]="'center'"
      [style.margin-left]="'auto'"
    >
      <nb-icon
        (click)="close()"
        class="closeIcon"
        icon="close-outline"
        pack="eva"
      >
      </nb-icon>
    </div>
  </nb-card-header>
  <nb-card-body>
    <pdf-viewer [src]="pdfURL" [render-text]="true" style="display: block;"></pdf-viewer>
  </nb-card-body>
</nb-card>